import { createI18n } from 'vue-i18n';

const messages = {
  en: {
    english: 'English',
    german: 'German',
    catalogue: 'Catalogue',
    dataSheets: 'Data sheets',
    videos: 'Videos',
    close: 'Close',
    back: 'Back',
    bandSaegen: 'Bandsaws',
    automatenWoelfe: 'Crossfeed Grinders',
    mischWoelfe: 'Mixer Grinders',
    stopfWoelfe: 'Manual Mincers',
    portionierer: 'Portioner',
    woelfe: 'Grinders',
    Automatenwölfe: 'Crossfeed Grinders',
    Mischwölfe: 'Mixer Grinders',
    Wölfe: 'Grinders',
    Portionierer: 'Portioner',
    Portionieren: 'Portioner',
    Bandsägen: 'Bandsaws',
    Stopfwölfe: 'Manual Mincers',
    "Mit Förderband": "with conveyor belt",
    Tomaten: 'Tomatoes',
    Käse: 'Cheese',
    "Installation Unger Schneidesystem": "Installation Unger Cutting System",
    "Reinigungszustand mit Förderband": "Cleaning state with conveyor belt"
  },
  de: {
    english: 'Englisch',
    german: 'Deutsch',
    catalogue: 'Katalog',
    dataSheets: 'Datenblätter',
    videos: 'Videos',
    close: 'Schließen',
    back: 'Zurück',
    bandSaws: 'Bandsägen',
    automatenWoelfe: 'Automatenwölfe',
    bandSaegen: 'Bandsägen',
    mischWoelfe: 'Mischwölfe',
    stopfWoelfe: 'Stopfwölfe',
    portionierer: 'Portionierer',
    woelfe: 'Wölfe',
    Automatenwölfe: 'Automatenwölfe',
    Mischwölfe: 'Mischwölfe',
    Wölfe: 'Wölfe',
    Portionierer: 'Portionierer',
    Bandsägen: 'Bandsägen',
    Stopfwölfe: 'Stopfwölfe',
    "Mit Förderband": "Mit Förderband"
  },
};

const i18n = createI18n({
  locale: localStorage.getItem('selectedLanguage') || 'de',
  fallbackLocale: 'de',
  legacy: false,
  messages,
});

export default i18n;
