import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'Startseite',
    component: () => import('../views/HomeView.vue'),
  },
  {
    path: '/catalogue/:lang',
    name: 'Katalog',
    component: () => import('../views/CatalogueView.vue'),
  },
  {
    path: '/datasheets/:category',
    name: 'Datenblatt Liste',
    component: () => import('../views/DataSheetListView.vue'),
  },
  {
    path: '/datasheets/:category/:name/:filePath',
    name: 'Datenblatt Detail',
    props: true,
    component: () => import('../views/DataSheetView.vue'),
  },
  {
    path: '/videos/:category',
    name: 'Video Liste',
    component: () => import('../views/VideoListView.vue'),
  },
  {
    path: '/videos/:category/:name/:filePath',
    name: 'Video Details',
    component: () => import('../views/VideoDetailView.vue'),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.name ? `${to.name} - Kolbe Foodtec` : 'Kolbe Foodtec';
  if (to.name === 'Datenblatt Detail') {
    document.title = `Datenblatt: ${to.params.name} - Kolbe Foodtec`;
  }
  if (to.name === 'Datenblatt Liste') {
    document.title = `Datenblätter: ${to.params.category} - Kolbe Foodtec`;
  }
  next();
});

export default router;