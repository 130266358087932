import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import './assets/tailwind.css'

const app = createApp(App)

const imageMap = {
    Automatenwölfe:
        "/kolbe_Produktgruppenbilder/Automatenwoelfe_AW160-240 + AW130-240_HFD OBEN RECHTS.png",
    Bandsägen: "/kolbe_Produktgruppenbilder/Bandsaegen_K430_ANSICHT 1.png",
    Mischwölfe: "/kolbe_Produktgruppenbilder/Mischwoelfe_MW160-200_ANSICHT 1.png",
    Wölfe: "/kolbe_Produktgruppenbilder/Mischwoelfe_MW160-200_ANSICHT 1.png",
    Portionierer:
        "/kolbe_Produktgruppenbilder/Portionierer_Hackfleischline MW + PM150.png",
    Stopfwölfe: "/kolbe_Produktgruppenbilder/Stopfwoelfe_SW100_ANSICHT 3.png",
};

function buildListItem(name, type, options) {
    const basePathMap = {
        Bandsägen: '/DATENBLAETTER_DATASHEETS/SAEGEN_BANDSAWS/DATENBLATT-Datasheet',
        Mischwölfe: '/DATENBLAETTER_DATASHEETS/MISCHWOELFE_MIXER_GRINDERS/DATENBLATT-Datasheet',
        Stopfwölfe: '/DATENBLAETTER_DATASHEETS/STOPFWOELFE_MANUAL_MINCERS/DATENBLATT-Datasheet',
        Portionierer: '/DATENBLAETTER_DATASHEETS/PORTIONIERER_PORTIONER/DATENBLATT-Datasheet',
        Automatenwölfe: '/DATENBLAETTER_DATASHEETS/AUTOMATENWOELFE_CROSSFEED_GRINDERS/DATENBLATT-Datasheet',
    }
    return {
        name,
        path: `${basePathMap[type]}-${options?.fileName || name}.pdf`,
        ...options,
    }
}

function buiVdeoListItem(name, type, options) {
    const basePathMap = {
        Bandsägen: '/VIDEOS/SAEGEN',
        Wölfe: '/VIDEOS/WOELFE',
        Portionierer: '/VIDEOS/PORTIONIEREN',
    }
    return {
        name,
        path: `${basePathMap[type]}/${options?.fileName || name}.mp4`,
        ...options,
    }
}

const videoMap = {
    Bandsägen: [
        buiVdeoListItem('Bandsägen', 'Bandsägen', { fileName: 'Bandsaegen' }),
        buiVdeoListItem('K440 3D', 'Bandsägen', { fileName: 'K440_3D' }),
        buiVdeoListItem('Reinigungszustand mit Förderband', 'Bandsägen', { fileName: 'Reinigungszustand' }),
    ],
    Wölfe: [
        buiVdeoListItem('AWM114 & 130', 'Wölfe', {fileName: 'AWM114&130-100_MEAT'}),
        buiVdeoListItem('AWM130 3D', 'Wölfe', {fileName: 'AWM130-100_3D'}),
        buiVdeoListItem('AWM130', 'Wölfe', {fileName: 'AWM130-100_CHEESE_KAESE', subtitle: 'Käse'}),
        buiVdeoListItem('AWM130', 'Wölfe', {fileName: 'AWM130-100_TOMATOES', subtitle: 'Tomaten'}),
        buiVdeoListItem('KDS', 'Wölfe'),
        buiVdeoListItem('AW Processing', 'Wölfe', {fileName: 'KOLBE_AW_PROCESSING_BLOCKS -8°C'}),
        buiVdeoListItem('MW160-330', 'Wölfe', {subtitle: 'Installation Unger Schneidesystem',fileName: 'MW160-330_INSTALLATION_UNGER_CUTTING_SYSTEM_MW160-330_INSTALLATION_UNGER_SCHNEIDSYSTEM'}),
        buiVdeoListItem('Wölfe', 'Wölfe', {fileName: 'WOELFE_MINCERS'}),
    ],
    Portionierer: [
        buiVdeoListItem('Portionieren', 'Portionierer'),
    ],
}

const fileMap = {
    Bandsägen: [
        buildListItem('K220', 'Bandsägen'),
        buildListItem('K260', 'Bandsägen'),
        buildListItem('K330', 'Bandsägen'),
        buildListItem('K330S', 'Bandsägen'),
        buildListItem('K330RS', 'Bandsägen'),
        buildListItem('K380RS', 'Bandsägen'),
        buildListItem('K430', 'Bandsägen'),
        buildListItem('K430S', 'Bandsägen'),
        buildListItem('K440RS', 'Bandsägen'),
        buildListItem('K440RS', 'Bandsägen', { subtitle: 'Mit Förderband', fileName: 'K440RS-mit-Foerderband-with-conveyor-belt' }),
        buildListItem('K430RT', 'Bandsägen'),
        buildListItem('K430H', 'Bandsägen'),
        buildListItem('K440H', 'Bandsägen'),
        buildListItem('K800FT', 'Bandsägen'),
        buildListItem('K800RT', 'Bandsägen'),
    ],
    Mischwölfe: [
        buildListItem('MWK32-45', 'Mischwölfe'),
        buildListItem('MWK114-45', 'Mischwölfe'),
        buildListItem('MWK32-80', 'Mischwölfe'),
        buildListItem('MWK114-80', 'Mischwölfe'),
        buildListItem('MW32-80', 'Mischwölfe'),
        buildListItem('MW114-80', 'Mischwölfe'),
        buildListItem('MW32-120', 'Mischwölfe'),
        buildListItem('MW114-120', 'Mischwölfe'),
        buildListItem('MW52-120', 'Mischwölfe'),
        buildListItem('MW130-120', 'Mischwölfe'),
        buildListItem('MW56-200', 'Mischwölfe'),
        buildListItem('MW160-200', 'Mischwölfe'),
        buildListItem('MW56-330', 'Mischwölfe'),
        buildListItem('MW160-330', 'Mischwölfe'),
    ],
    Stopfwölfe: [
        buildListItem('LW22', 'Stopfwölfe'),
        buildListItem('LW82', 'Stopfwölfe'),
        buildListItem('TWK32', 'Stopfwölfe'),
        buildListItem('TWK98', 'Stopfwölfe'),
        buildListItem('TW100', 'Stopfwölfe'),
        buildListItem('TW98', 'Stopfwölfe'),
        buildListItem('SW100', 'Stopfwölfe'),
        buildListItem('SW98', 'Stopfwölfe'),
        buildListItem('SW100H', 'Stopfwölfe'),
        buildListItem('SW98H', 'Stopfwölfe'),
        buildListItem('SW114-I', 'Stopfwölfe'),
        buildListItem('SW114-II', 'Stopfwölfe'),

    ],
    Automatenwölfe: [
        buildListItem('AWM114-100', 'Automatenwölfe'),
        buildListItem('AWM130-100', 'Automatenwölfe'),
        buildListItem('AW52-240', 'Automatenwölfe'),
        buildListItem('AW130-240', 'Automatenwölfe'),
        buildListItem('AW56-240', 'Automatenwölfe'),
        buildListItem('AW160-240', 'Automatenwölfe'),
        buildListItem('AWM52-240', 'Automatenwölfe'),
        buildListItem('AWM130-240', 'Automatenwölfe'),
        buildListItem('AWM56-240', 'Automatenwölfe'),
        buildListItem('AWM160-240', 'Automatenwölfe'),
    ],
    Portionierer: [
        buildListItem('PM150', 'Portionierer'),
        buildListItem('PF110', 'Portionierer'),
        buildListItem('MO1500-K', 'Portionierer'),
    ],
};

const homeDataSheetsList = [
    {
        name: "bandSaegen",
        link: "/datasheets/Bandsägen",
    },
    {
        name: "stopfWoelfe",
        link: "/datasheets/Stopfwölfe",
    },
    {
        name: "mischWoelfe",
        link: "/datasheets/Mischwölfe",
    },
    {
        name: "automatenWoelfe",
        link: "/datasheets/Automatenwölfe",
    },
    {
        name: "portionierer",
        link: "/datasheets/Portionierer",
    },
];

const homeVideosList = [
    {
        name: "bandSaegen",
        link: "/videos/Bandsägen",
    },
    {
        name: "woelfe",
        link: "/videos/Wölfe",
    },
    {
        name: "portionierer",
        link: "/videos/Portionierer",
    },
];

app.provide('imageMap', imageMap)
    .provide('fileMap', fileMap)
    .provide('videoMap', videoMap)
    .provide('homeVideosList', homeVideosList)
    .provide('homeDataSheetsList', homeDataSheetsList)
    .provide('publicPath', process.env.NODE_ENV === "production" ? "./" : "")
    .use(router)
    .use(i18n)
    .mount('#app')
